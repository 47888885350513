import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
Represents a link element.
@PublicAPI
@deprecated - this is moved to libs
*/
export type LinkDefinition = {
    charset?: string;
    crossorigin?: string;
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
} & {
    [prop: string]: string;
};
/**

A service that can be used to get and add link tags.
@PublicAPI
@deprecated - this is moved to libs
*/
@Injectable({ providedIn: 'root' })
export class LinkService {

    constructor(
        @Inject(DOCUMENT) private dom: Document,
    ) { }

    public getTag(attrSelector: string): HTMLLinkElement | null {
        if (!attrSelector) {
            return null;
        }

        return this.dom.querySelector(`link[${attrSelector}]`) || null;
    }

    public getTags(attrSelector: string): HTMLLinkElement[] {
        if (!attrSelector) {
            return [];
        }
        const list: NodeListOf<Element> = this.dom.querySelectorAll(`link[${attrSelector}]`);
        return list ? [].slice.call(list) : [];
    }

    public addTag(tag: LinkDefinition): HTMLLinkElement | null {
        if (!tag) {
            return null;
        }

        return this.createCanonicalLink(tag);
    }

    private createCanonicalLink(tag: LinkDefinition): HTMLLinkElement {
        const oldLink: HTMLLinkElement = this.dom.querySelector('link[rel=canonical]');
        if (oldLink) {
            oldLink.remove();
        }

        const canURL = tag.href === undefined ? this.dom.URL : tag.href;
        const link: HTMLLinkElement = this.dom.createElement('link');

        link.setAttribute('rel', tag.rel);
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);

        return link;
    }

    public updateTag(tag: LinkDefinition, selector?: string): HTMLLinkElement | null {
        if (!tag) {
            return null;
        }

        selector = selector || this._parseSelector(tag);
        const meta: HTMLLinkElement = this.getTag(selector);
        if (meta) {
            meta.setAttribute('rel', tag.rel);
            this.dom.head.appendChild(meta);
        }
        return this._getOrCreateElement(tag, true);
    }

    public removeTag(attrSelector: string): void {
        this.removeTagElement(this.getTag(attrSelector));
    }

    public removeTagElement(meta: HTMLLinkElement): void {
        if (meta) {
            this.dom.head.removeChild(meta);
        }
    }

    private _parseSelector(tag: LinkDefinition): string {
        const attr: string = tag.name ? 'name' : 'property';
        return `${attr}="${tag[attr]}"`;
    }

    private _setMetaElementAttributes(tag: LinkDefinition, el: HTMLLinkElement): HTMLLinkElement {
        Object.keys(tag).forEach((prop: string) => el.setAttribute(prop, tag[prop]));
        return el;
    }

    private _getOrCreateElement(meta: LinkDefinition, forceCreation: boolean = false): HTMLLinkElement {
        if (!forceCreation) {
            const selector: string = this._parseSelector(meta);
            const elem: HTMLLinkElement = this.getTag(selector);
            // It's allowed to have multiple elements with the same name so it's not enough to
            // just check that element with the same name already present on the page. We also need to
            // check if element has tag attributes
            if (elem && this._containsAttributes(meta, elem)) {
                return elem;
            }
            const element: HTMLLinkElement = this.dom.createElement('link') as HTMLLinkElement;
            this._setMetaElementAttributes(meta, element);
            this.dom.head.appendChild(element);
            return element;
        }
    }

    private _containsAttributes(tag: LinkDefinition, elem: HTMLLinkElement): boolean {
        return Object.keys(tag).every((key: string) => elem.getAttribute(key) === tag[key]);
    }
}
